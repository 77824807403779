/* https://fkhadra.github.io/react-toastify/how-to-style/ */

:root {
    /* improve contrast for accessibility */
    --toastify-color-error: crimson;
    --toastify-color-success: green;

    /* kendo overlays start at k-overlay 10001 and will perform automatic z-index stacking as dialogs are shown - we need to ensure toast notifications sit over these */
    --toastify-z-index: 15000;
}
