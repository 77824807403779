/* NOTE: this needs to be kept in sync with neats-secure-portal */

/*
  rules regarding overrides:
  1. only override in here when you can't change it in the sass
  2. don't override here if you can set the style directly in your component
  3. always put comments against why you're overriding a particular style
  4. obviously only global overrides go in here
  5. this file should be kept small
*/

/* ---------- general overrides ---------- */

/* ---------- toastr ---------- */
/* most of these overrides relate to making the theme more accessible, by improving contrast */
.redux-toastr .toastr {
    opacity: 100 !important;
}

.redux-toastr .rrt-progressbar {
    background-color: white !important;
}

.redux-toastr .toastr.rrt-error {
    background-color: crimson;
}

.redux-toastr .toastr.rrt-success {
    background-color: green;
}

/* ---------- kendo overrides ---------- */
/* please do most of this in the sass! */

/* override kendo grid link behaviour, by default links just look like normal text */
.k-grid a {
    color: var(--bs-link-color);
}

/* relocate grid paging controls to top */
.k-grid-pager {
    order: -1;
    border-width: 0 0 1px;
}
